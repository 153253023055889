// @use "sass:list";
@use '../variable';
@use '../mixin/media' as mixin_media;

// @mixin parallax_keyframe($name, $begin-percent: 0%, $end-percent: 100%, $properties: ()) {
//   @keyframes parallax-#{$name} {
//     @if $begin-percent != 0% {
//       0% {
//         @each $prop in $properties {
//           #{$prop}: var(--parallax-begin-#{$prop});
//         }
//       }
//     }
//     #{$begin-percent} {
//       @each $prop in $properties {
//         #{$prop}: var(--parallax-begin-#{$prop});
//       }
//     }
//     #{$end-percent} {
//       @each $prop, $value in $properties {
//         #{$prop}: var(--parallax-end-#{$prop});
//       }
//     }
//     @if $end-percent != 100% {
//       100% {
//         @each $prop, $value in $properties {
//           #{$prop}: var(--parallax-end-#{$prop});
//         }
//       }
//     }
//   }
// }

// // parallax_element common use cases:
// // Timeline:
// // - `scroll(root y)` - General root scroll y position tracking.
// // - `view(block)` - Tracking what is on screen, best for different zoom levels.
// //   - `animation-range-start: variable.$page_height;` for beginning animation at the top of the screen
// @mixin parallax_element($name, $timing_function, $timeline, $properties: ()) {
//   animation: parallax-#{$name} #{$timing_function};
//   animation-timeline: #{$timeline};
//   animation-fill-mode: forwards;

//   @each $prop, $values in $properties {
//     --parallax-begin-#{$prop}: #{list.nth($values, 1)};
//     --parallax-end-#{$prop}: #{list.nth($values, 2)};
//   }
// }

// body::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   height: 491px; // maintain view()-based animation speed consistency by not changing height instead of using 'bottom'
//   // max-height: variable.$page_height_small;
//   background-image: url('/asset/image/interface/background/index/level_oldharbor.png');
//   background-repeat: no-repeat;
//   background-position-x: center;
//   background-position-y: top;
// }

// // @media (prefers-reduced-motion: no-preference) {
// @supports (animation-timeline: view()) {
//   @include parallax_keyframe(backdrop, 0%, 100%, (
//     opacity, transform
//     // bottom // prevent going past bottom of page
//   ));

//   body::before {
//     // $translate_y__distance: 25rem;
//     $translate_y__distance: 400px; // cannot exceed image height!

//     @include parallax_element(backdrop, linear, view(block), (
//       opacity: (1, 0),
//       transform: (translateY(0rem), translateY($translate_y__distance))
//       // background-position-y: (0rem, $translate_y__distance)
//       // bottom: (0rem, $translate_y__distance) // prevent going past bottom of page
//     ));

//     animation-range-start: variable.$page_height;
//   }
// }
// // }

// // body::before,
// // #title,
// // #title > *
// // {
// //   border: 2px yellow solid;
// // }

// main section {
//   position: relative;
// }

// @keyframes animate-intro {
//   to {
//     transform: translateY(0px);
//     opacity: 1;
//   }
// }

// #title {
//   /* min-height: 24rem; */
//   padding-top: 4rem;
//   padding-bottom: 4rem;
//   background: radial-gradient(rgba(0, 0, 0, 0.3) 30%, transparent 70%);
// }

// // @include mixin_media.media('>phone-large') {
// @supports (animation-timeline: view()) {
//   @include parallax_keyframe(title__section, 0%, 100%, (
//     transform
//   ));

//   @include parallax_keyframe(title__header, 0%, 100%, (
//     opacity
//   ));

//   $translate_y__distance: 5rem;

//   #title {
//     @include parallax_element(title__section, cubic-bezier(0.75, 0.75, 0.5, 1), view(), (
//       transform: (translateY(0rem), translateY($translate_y__distance))
//     ));

//     animation-range-start: calc(variable.$page_height - 77px);
//     animation-range-end: calc(variable.$page_height + 30%);

//     overflow: initial;

//     h1 {
//       @include parallax_element(title__header, cubic-bezier(0.75, 0.75, 0.5, 1), view(), (
//         opacity: (1, 0)
//       ));

//       // animation-range-start: calc(115vh);
//       // animation-range-end: calc(variable.$page_height - 100%);

//       // animation-range: cover 85vh cover 95vh;

//       animation-range-start: calc(variable.$page_height + ($translate_y__distance));
//       // animation-range-end: calc(variable.$page_height + ($translate_y__distance + 7.5%));
//       animation-range-end: calc(variable.$page_height + ($translate_y__distance * 2.0));
//     }
//   }
// }
// // }

// .text--intro {
//   color: #5cb3de;
//   text-shadow: 0px 0.1em 2px rgba(0, 0, 0, 0.7);
//   width: fit-content;
//   margin-left: auto;
//   margin-right: auto;
// }

// html.backgroundcliptext .text--intro, html.backgroundcliptext .text--intro::after {
//   // background: linear-gradient(to right, #e4b4ff, #b0e9ff);
//   // background: linear-gradient(to right, #ffdfa0, #b7832b);
//   // background: linear-gradient(150deg, #ffdfa0, #b7832b);
//   // background: linear-gradient(to right, #5cb3de, #317596);
//   // background: linear-gradient(150deg, #5cb3de, #317596);
//   background: linear-gradient(to right, #7bd3ff, #2292ca);

//   filter: drop-shadow(0.05em 0.05em 0.05em #000)
// }

// html.backgroundcliptext .text--intro {
//   color: transparent;
//   -webkit-background-clip: text;
//   text-shadow: none;
// }

// html.backgroundcliptext .text--intro::after {
//   opacity: 0.5;
// }

// hide navbar on index page

#nav_header {
  height: 0px !important;
  display: none !important;
}

#main {
  padding-top: 0px !important;
}

#title {
  padding-top: variable.$page_gap_3;
  padding-bottom: variable.$page_gap_3;
}

#title-logo {
  margin-left: auto;
  margin-right: auto;
  max-width: 24rem;
  padding-top: 2rem;

  img {
    margin: 0px;
  }
}

#intro-slideshow {
  .hero {
    margin-top: -1.5rem !important;

    p {
      // background: radial-gradient(circle, #5793abff 0%, #366a89ff 100%);
      background: radial-gradient(circle, #5793abff 0%, #366a89ff 100%);
      -webkit-background-clip: text;
      background-clip: text;
      // color: #5897bb;
      color: transparent;
      font-size: 2em; /* Adjust font size as needed */
      font-weight: bold;
      // text-shadow: 0 variable.$page_gap_0 (variable.$page_gap_0 * 0.5) variable.$page_color_shadow_alpha_60;
      filter: drop-shadow(0 variable.$page_gap_0 variable.$page_gap_0 * 0.5 variable.$theme_color_shadow);
      margin-bottom: 0px;
      margin-top: 3rem;
      line-height: 1.5;
      z-index: 2;
    }

    &::before {
      // display: none;
      // background-color: #00000066;
      background: radial-gradient(circle at center 15rem, #000000ee 37%, #00000011 100%);
      // background-position-y: 27rem;
      // background-repeat: no-repeat;
    }

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url('/asset/image/brand/logo_effect_foreground.png');
      background-size: cover;
      background-position: center;
      content: "";
      z-index: 1;
      opacity: 0.5;
    }
  }

  a {
    margin: 0px;
    padding: 0px;
    text-align: center;
    width: 100%;

    .slideshow-fade {
      position: relative;
      overflow: hidden;

      padding-bottom: 25%;
    }

    img {
      margin: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      
      width: 100%;
      height: auto;
      min-height: initial;
      max-height: initial;

      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      min-width: initial;
      max-width: initial;
      transform: translate(-50%,-50%);

      width: 100%;
    }

    p {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-weight: normal;
    }
  }
}

@include mixin_media.media("<tablet") {
  // #title {
  //   padding: 0px !important;
  //   height: 0px;

  //   img {
  //     display: none;
  //   }
  // }

  #intro-slideshow {
    a {
      max-width: 24rem;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}

// @include mixin_media.media(">=tablet") {
//   #nav_header__top__left {
//     display: none;
//   }

//   #nav_header__row__button {
//     float: none;
//     display: block !important;
//     width: auto;
//     margin: 0px;
//   }
// }

@include mixin_media.media(">desktop-small") {
  #intro-slideshow {
    a {
      .slideshow-fade {
        padding-bottom: 56.25%;
      }
    }
  }
}

.button--news--more {
  padding: 1rem 2rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  // width: fit-content;
  max-width: 100%;
  width: 20rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  // border: 0.2rem solid;
}

#news_articles>:nth-child(n+4) {
  display: none;
}
